import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchVisitorList,
  fetchChaptList,
  fetchchapterbyuser,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { deleteVisitorData, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function Visitor() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [visitorData, setvisitorData] = useState([]);

  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );
  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getvisitorlist = async (ch_id, m_id) => {
    setvisitorData(await fetchVisitorList(ch_id, m_id));
  };
  const deleteVisitor = async (v_id) => {
    const resp = await deleteVisitorData(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getvisitorlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Invite By", key: "v_by_name" },
    { label: "Visitor Name", key: "visitor_name" },
    { label: "Visitor Business", key: "visitor_business_name" },
    { label: "Contact Number", key: "visitor_mo_no" },
    { label: "Business Category", key: "visitor_business_category" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Invite By",
      selector: (row) => row.v_by_name + " (" + row.member_Business + ")",
      sortable: true,
    },
    {
      name: "Visitor Name",
      selector: (row) => row.visitor_name,
      sortable: true,
    },
    {
      name: "Visitor Business",
      selector: (row) => row.visitor_business_name,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.visitor_mo_no,
      sortable: true,
    },
    {
      name: "Business Category",
      selector: (row) => row.visitor_business_category,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,

      selector: (row) => (
        <>
          <>
            <Tooltip title="Delete Visitor">
              <IconButton
                onClick={() => {
                  setDvisible({ mval: true, id: row.v_id });
                }}
              >
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </>
        </>
      ),
    },
  ];
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteVisitor}
      />
      <Page title="Visitor | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Visitor List
            </Typography>
          </Stack>

          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <ChapterSelectDropDown
              dropDownLabel={"Select Chapter"}
              selectedState={filter}
              chapterKey="chpt_id"
              handleChange={(e) => {
                setFilter({ chpt_id: e.value });
                getmemberbychp(e.value);
              }}
            />
          </Stack>
          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }}>Select Member</label>
            <Select
              value={selectedmember.defaulselect}
              onChange={(e) => {
                setselectedmember({ defaulselect: e, m_id: e.value });
                getvisitorlist(filter.chpt_id, e.value);
              }}
              options={
                memberdata &&
                memberdata.map((option) => {
                  return { value: option.m_id, label: option.m_name };
                })
              }
            />
          </Stack>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {visitorData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={visitorData}
                    headers={expo_columns}
                    filename={
                      "Visitor Data" + moment().format("DD-MM-YYYY") + ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={visitorData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
