import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchReferenceList,
  fetchchapterbyuser,
  fetchReferenceListByMember,
  fetchReferenceByMember,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  deleteBusiness,
  deleteReference,
  GetMemberByChptId,
} from "src/api/commonapi";
import { DeleteSharp, Filter } from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function ReferenceReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [nreferenceData, setnreferenceData] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [filter, setFilter] = useState({
    chpt_id: 0,
    m_id: 0,
    startDate: "",
    endDate: "",
    selectType: 0,
    startDate: "",
    crossType: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const filterData = () => {
    let nref = [];
    nref =
      referenceData &&
      referenceData.filter((ref) => {
        if (
          (filter.selectType == 1 && ref.r_to_id == filter.m_id) ||
          (filter.selectType == 2 && ref.r_by_id == filter.m_id) ||
          filter.selectType == 0
        ) {
          if (filter.startDate != "" && filter.endDate != "") {
            if (
              moment(ref.r_date, "DD-MM-YYYY").isBetween(
                filter.startDate,
                filter.endDate,
                "days",
                "[]"
              )
            ) {
              let res = handleCrossFilter(ref);
              return res;
            }
          } else {
            let res = handleCrossFilter(ref);
            return res;
          }
        }
      });
    setnreferenceData(nref);
  };

  const handleCrossFilter = (ref) => {
    if (filter.crossType == 1 || filter.crossType == 2) {
      if (ref.inOutChapter == filter.crossType) {
        return ref;
      }
    } else {
      return ref;
    }
  };

  useEffect(async () => {
    await filterData();
  }, [referenceData, filter]);

  const getChapterById = async (ch_id) => {
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getbusinesslist = async (ch_id, m_id) => {
    let res = await await fetchReferenceByMember(ch_id, m_id);

    let newData = res.map((reference) => {
      if (reference.r_to_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(reference.r_by_id)
          ? { ...reference, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...reference, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      } else if (reference.r_by_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(reference.r_to_id)
          ? { ...reference, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...reference, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      }
    });
    setReferenceData(newData);
  };

  useEffect(async () => {
    getchptlist();
  }, []);

  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "Reference Giver", key: "r_by_name" },
    { label: "Reference Receiver", key: "r_to_name" },
    { label: "Date", key: "r_date" },
    { label: "Reference Name", key: "r_name" },
    { label: "Contact No", key: "r_mobile_no" },
    { label: "Note", key: "r_note" },
    { label: "Rating", key: "r_rating" },
    { label: "In/Out Chapter", key: "outChapterLabel" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row, key) => row.row_no,
      sortable: true,
      width: "80px",
    },
    {
      name: "Reference Giver",
      selector: (row) => row.r_by_name,
      sortable: true,
    },
    {
      name: "Reference Receiver",
      selector: (row) => row.r_to_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.r_date,
      sortable: true,
    },
    {
      name: "Reference Name",
      selector: (row) => row.r_name,
      sortable: true,
    },
    {
      name: "Contact No",
      selector: (row) => row.r_mobile_no,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.r_note,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.r_rating,
      sortable: true,
      width: "100px",
    },
    {
      name: "In/Out Chapter",
      selector: (row) => (
        <div>
          <Label
            variant={"ghost"}
            color={row.inOutChapter == 1 ? "success" : "info"}
          >
            {row.outChapterLabel}
          </Label>
        </div>
      ),
    },
  ];
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "Reference Receiver" },
    { value: 2, label: "Reference Giver" },
  ]);

  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "In-Chapter" },
    { value: 2, label: "Cross-Chapter" },
  ]);

  const changeChapterHandler = async (e) => {
    setFilter({ ...filter, chpt_id: e.value, m_id: 0 });
    setnreferenceData([]);
    await getChapterById(e.value);
  };
  return (
    <>
      <Page title="Reference Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Reference Report
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filter}
                  chapterKey="chpt_id"
                  handleChange={changeChapterHandler}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Member</label>
                <Select
                  value={memberdata.map((member) => {
                    if (member.m_id == filter.m_id) {
                      return { value: member.m_id, label: member.m_name };
                    }
                  })}
                  onChange={(e) => {
                    setFilter({ ...filter, m_id: e.value });
                    getbusinesslist(filter.chpt_id, e.value);
                  }}
                  options={
                    memberdata &&
                    memberdata.map((option) => {
                      return { value: option.m_id, label: option.m_name };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Type</label>
                <Select
                  value={filterTypeOptions.map((c) => {
                    if (c.value == filter.selectType) {
                      return { value: c.value, label: c.label };
                    }
                  })}
                  onChange={async (e) => {
                    await setFilter({ ...filter, selectType: e.value });
                  }}
                  options={filterTypeOptions}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>
                  In / Cross Chapter
                </label>
                <Select
                  value={inOutChapterOptions.map((c) => {
                    if (c.value == filter.crossType) {
                      return { value: c.value, label: c.label };
                    }
                  })}
                  onChange={async (e) => {
                    setFilter({ ...filter, crossType: e.value });
                  }}
                  options={inOutChapterOptions}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={filter?.startDate != "" ? filter?.startDate : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date "
                    value={filter?.endDate != "" ? filter?.endDate : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    filterData(filter?.startDate, filter?.endDate);
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilter({
                      chpt_id: 0,
                      m_id: 0,
                      startDate: "",
                      endDate: "",
                      selectType: 0,
                      crossType: 0,
                    });
                    setReferenceData([]);
                    setnreferenceData([]);
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {nreferenceData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nreferenceData}
                    headers={expo_columns}
                    filename={"ReferenceData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nreferenceData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
