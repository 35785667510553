import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Label from "../components/Label";

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  AccountCircle,
  ArticleOutlined,
  ContentCopy,
  EditSharp,
  Person,
  PersonAdd,
  PersonAddAlt,
} from "@mui/icons-material";
// components
import { CityForm } from "../components/Form/City";
import Modal from "@mui/material/Modal";

import Page from "../components/Page";
import {
  fetchallcitylist,
  fetchchapterlistbycity,
  fetchcityambassadorbyid,
} from "src/utils/common";
import {
  UpdateCityStatus,
  insertcity,
  GetActiveMemberByChpt,
  insertcityambassador,
  updateCityAmbssadorDetails,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import { fetchallstatelist } from "src/utils/common";
import { updatecity } from "src/api/commonapi";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { Routes } from "src/constant/api_url";

export default function City() {
  const [step, setStep] = useState(1);
  const [citylist, setcitylist] = useState([]);
  const [newcitylist, setnewcitylist] = useState([]);
  const [filtercity, setfiltercity] = useState("");
  const [statelist, setstatelist] = useState([]);
  const [formdata, setformdata] = useState({ m_r_fee: 0, n_m_fee: 0 });
  const [myModal, setMyModal] = useState({ mv: false, step: 0 });
  const [option, setOption] = useState([]);
  const [chapterlist, setchapterlist] = useState([]);
  const [cadata, setcadata] = useState([]);
  const [memberlist, setmemberlist] = useState([]);
  let par = useParams();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4,
  };

  const getstate = async () => {
    setstatelist(await fetchallstatelist());
  };

  const getchapterlistbycity = async (city_id) => {
    setchapterlist(await fetchchapterlistbycity(city_id));
  };

  const getmemberlistbychapter = async (chpt_id) => {
    setmemberlist(await GetActiveMemberByChpt(chpt_id));
  };

  const getcityambassadorbyid = async (member_id) => {
    const resp = await fetchcityambassadorbyid(member_id);
    setcadata({ ...cadata, ...resp });
    await getmemberlistbychapter(resp.chpt_id);
  };

  useEffect(async () => {
    getstate();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let resp;

    if (step == 1) {
      if (
        formdata.state_id == 0 ||
        formdata.state_id == undefined ||
        formdata.city_name == 0 ||
        formdata.city_name == undefined
      ) {
        let notify = notification({
          type: "error",
          message: "Please Fill Required Fields",
        });
        notify();
      } else {
        const jscitydata = JSON.stringify(formdata);
        resp = await insertcity(jscitydata);
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        if (resp.status == 1) {
          setstatelist(null);
          setformdata({ m_r_fee: 0, n_m_fee: 0 });
          response();
        }
      }
      getallcitylist();
    }
    if (step == 2) {
      resp = await updatecity(JSON.stringify(formdata));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      setformdata({ m_r_fee: 0, n_m_fee: 0 });
      getallcitylist();
    }
  };

  const changestatus = async (city_id, c_status) => {
    let fdata = new FormData();
    fdata.append("city_id", city_id);
    fdata.append("c_status", c_status);

    const resp = await UpdateCityStatus(fdata);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    getallcitylist();
  };

  const filterData = () => {
    let ncity = [];
    ncity =
      citylist &&
      citylist.filter((city) => {
        if (city.city_name.toLowerCase().includes(filtercity?.toLowerCase())) {
          return city;
        }
      });

    setnewcitylist(ncity);
  };

  useEffect(async () => {
    if (filtercity != undefined) {
      await filterData();
    }
  }, [filtercity, citylist]);

  const getallcitylist = async () => {
    setcitylist(await fetchallcitylist());
  };

  const onChangeFunction = (e) => {
    setcadata({ ...cadata, [e.target.name]: e.target.value });
  };

  const response = () => {
    getallcitylist();
  };

  const cancelModal = () => {
    setMyModal({ mv: false, step: 0 });
    setchapterlist([]);
    setmemberlist([]);
    setcadata([]);
  };
  const openInNewTab = async (code) => {
    let customURL = Routes.LEADFORMPUBLICURL + "lvb-membership-form/" + code;
    let url = window.location.origin + "/lvb-membership-form/" + code;
    navigator.clipboard.writeText(customURL);
    let notify = notification({
      type: "info",
      message: "Copy to clipboard",
      position: "top",
    });
    await notify();
  };
  const setCityFormLink = async (city_id) => {
    const resp = await fetchcityambassadorbyid(city_id);
    let data = resp && resp.u_id.toString();
    let buff = new Buffer(data);
    let base64data = buff.toString("base64");
    openInNewTab(base64data);
  };

  useEffect(async () => {
    getallcitylist();
  }, []);

  const expo_columns = [
    { label: "No", key: "city_id" },
    { label: "City Name", key: "city_name" },
    { label: "State Name", key: "state_name" },
  ];
  const columns = [
    {
      name: "No",
      selector: (row) => row.city_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "City Name",
      selector: (row) => row.city_name,
      sortable: true,
    },
    {
      name: "New Member Fee",
      selector: (row) => row.n_m_fee,
      sortable: true,
    },
    {
      name: "Renewal Member Fee",
      selector: (row) => row.m_r_fee,
      sortable: true,
    },

    {
      name: "State Name",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.c_status == 0 ? (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="success"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  Active
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.city_id, 1);
                  }}
                  style={{ background: "#ff4842" }}
                >
                  Deactive
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ margin: "10px 0" }}>
                <Label
                  variant="ghost"
                  color="error"
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  Deactive
                </Label>

                <Button
                  variant="contained"
                  onClick={() => {
                    changestatus(row.city_id, 0);
                  }}
                >
                  Active
                </Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <>
          <a
            onClick={async () => {
              setformdata(row);
              setStep(2);
              getstate();
            }}
          >
            <Tooltip title="Edit City">
              <IconButton>
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
          </a>
          <a
            onClick={async () => {
              setCityFormLink(row.city_id);
            }}
          >
            <Tooltip title="Click to Copy Link">
              <IconButton>
                <ContentCopy color="dark" />
              </IconButton>
            </Tooltip>
          </a>
        </>
      ),
    },
  ];
  const tableData = {
    columns,
    citylist,
  };

  const submitCaData = async () => {
    if (
      cadata.chpt_id == undefined ||
      cadata.member_id == undefined ||
      cadata.email == undefined ||
      cadata.password == undefined ||
      cadata.u_commission == undefined
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill Required Fields",
      });
      notify();
    } else {
      let resp;
      if (myModal.step == 1) {
        resp = await insertcityambassador(JSON.stringify(cadata));
      }
      if (myModal.step == 2) {
        resp = await updateCityAmbssadorDetails(JSON.stringify(cadata));
      }
      if (myModal.step == 3) {
      }
      if (resp.status == 1) {
        let notify = notification({
          type: resp.status == 0 ? "error" : "success",
          message: resp.message,
        });
        notify();
        setMyModal({ mv: false, step: 0 });
        getallcitylist();
        setchapterlist([]);
        setmemberlist([]);
        setcadata([]);
      }
    }
  };

  return (
    <>
      <Modal
        open={myModal.mv}
        onClose={cancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ borderBottom: "2px solid #000" }}
          >
            Allocate City Ambassador
          </Typography>
          <div style={{ padding: "10px" }}>
            <div style={{ maxHeight: "320px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <label style={{ marginBottom: "10px", fontWeight: "bold" }}>
                      Chapter
                    </label>
                    <Select
                      value={
                        chapterlist &&
                        chapterlist
                          ?.map((c) => {
                            if (c.chpt_id == cadata.chpt_id) {
                              return { value: c.chpt_id, label: c.chpt_name };
                            }
                          })
                          .filter((n) => n)[0]
                      }
                      onChange={(e) => {
                        setcadata({ ...cadata, chpt_id: e.value });
                        getmemberlistbychapter(e.value);
                      }}
                      options={
                        chapterlist &&
                        chapterlist.map((p) => {
                          return { value: p.chpt_id, label: p.chpt_name };
                        })
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <label style={{ marginBottom: "10px", fontWeight: "bold" }}>
                      Member
                    </label>
                    <Select
                      value={
                        memberlist &&
                        memberlist
                          .map((c) => {
                            if (c.m_id == cadata.member_id) {
                              return {
                                value: c.m_id,
                                label: c.m_name,
                                mo_no: c.mobile_no,
                              };
                            }
                          })
                          .filter((n) => n)[0]
                      }
                      onChange={(e) =>
                        setcadata({
                          ...cadata,
                          member_id: e.value,
                          name: e.label,
                          mobile: e.mo_no,
                        })
                      }
                      options={
                        memberlist &&
                        memberlist.map((p) => {
                          return {
                            value: p.m_id,
                            label: p.m_name,
                            mo_no: p.mobile_no,
                          };
                        })
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      value={cadata?.email ? cadata?.email : ""}
                      type="text"
                      label="Email"
                      name="email"
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      sstyle={{ marginTop: "20px" }}
                      fullWidth
                      autoComplete="off"
                      value={cadata?.password ? cadata?.password : ""}
                      type="text"
                      label="Password"
                      name="password"
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      value={cadata?.u_commission ? cadata?.u_commission : ""}
                      type="text"
                      label="Commission Percentage"
                      name="u_commission"
                      onChange={onChangeFunction}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <hr />
            <div
              style={{
                margin: "10px auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={cancelModal}>
                Close
              </Button>
              <Button variant="contained" onClick={submitCaData}>
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Page title="City | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              City
            </Typography>
          </Stack>
          {/* Start Category Form */}
          <Card style={{ padding: "25px", marginBottom: "25px" }}>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select State</label>
              <Select
                value={
                  statelist != null &&
                  statelist
                    .map((c) => {
                      if (c.state_id == formdata?.state_id) {
                        return { value: c.state_id, label: c.state_name };
                      }
                    })
                    .filter((n) => n)[0]
                }
                style={{ marginTop: "0px" }}
                name="state"
                onChange={(e) =>
                  setformdata({ ...formdata, state_id: e.value })
                }
                options={
                  statelist != null &&
                  statelist.map((chpt) => {
                    return { value: chpt.state_id, label: chpt.state_name };
                  })
                }
              ></Select>
            </Stack>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                name="city_name"
                label="City Name"
                value={formdata.city_name ? formdata.city_name : ""}
                onChange={(e) =>
                  setformdata({ ...formdata, city_name: e.target.value })
                }
              />
            </Stack>
            <Stack spacing={10} style={{ marginBottom: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      value={formdata.n_m_fee}
                      type="number"
                      pattern="[0-9]"
                      label="New Member Fees"
                      name="n_m_fee"
                      onChange={(e) =>
                        setformdata({ ...formdata, n_m_fee: e.target.value })
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={10} style={{ marginBottom: "15px" }}>
                    <TextField
                      style={{ marginTop: "0px" }}
                      fullWidth
                      autoComplete="off"
                      value={formdata.m_r_fee}
                      type="number"
                      pattern="[0-9]"
                      label="Renewal Member Fees"
                      name="m_r_fee"
                      onChange={(e) =>
                        setformdata({ ...formdata, m_r_fee: e.target.value })
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Button
              style={{ marginTop: "15px" }}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
            >
              Add City
            </Button>
          </Card>
          {/* End Category Form */}

          <Card style={{ padding: "25px" }}>
            <Grid item xs={6}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label> Search City </label>
                <TextField
                  style={{ marginTop: "5px" }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Search City"
                  name="search"
                  onChange={(e) => {
                    setfiltercity(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
            {citylist && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={newcitylist}
                    headers={expo_columns}
                    filename={"City Data.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  title="City List"
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={newcitylist}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
