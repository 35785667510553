import React, { useState, useEffect } from "react";
import Board from "react-trello";
import "../Assets/css/style.css";
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Grid,
  Box,
  Checkbox,
  MenuItem,
  FormLabel,
  CardFormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
  Alert,
  Menu,
} from "@mui/material";

import "@lourenci/react-kanban/dist/styles.css";
import Select from "react-select";
import Page from "../components/Page";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import {
  fetchallocatelistbyid,
  fetchstaffbypid,
  fetchstaffListbypid,
  fetchtaskbytid,
  fetchtasklistbyid,
} from "src/utils/common";
import {
  deleteTask,
  insertTask,
  updatetaskdetails,
  updateTaskPrority,
  updatetaskstatus,
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import {
  CancelOutlined,
  CancelRounded,
  Check,
  DeleteForever,
  DeleteForeverOutlined,
  DeleteSharp,
  ErrorOutlineRounded,
  ErrorRounded,
  Flag,
  LocalOffer,
  QuestionMarkOutlined,
  ReportGmailerrorredOutlined,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Deletemodal from "./DeleteModal";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
export default function Task() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [step, setStep] = useState(0);
  const [taskDeleteModal, settaskDeleteModal] = useState({
    mval: false,
    t_id: 0,
  });
  const [taskdata, setTaskData] = useState([]);
  const [newtaskData, setnewTaskData] = useState([]);
  const [data, setData] = useState({});
  const [formdata, setformdata] = useState({
    u_created_id: loginUser.u_id,
    u_type: loginUser.u_type,
  });
  const [allocatelist, setallocatelist] = useState([]);
  const [anchorEl, setAnchorEl] = useState({ id: 0 });
  const [filter, setFilter] = useState({
    defaulselect: { value: 0, label: "All Priority" },
    t_priority: 0,
  });
  const [filtertask, setfiltertask] = useState({
    filterpriority: 0,
    filtert: "",
  });

  const handleClose = () => {
    setAnchorEl(false);
  };
  const priorityList = [
    { value: 4, label: "Urgent", color: "error" },
    { value: 3, label: "High", color: "warning" },
    { value: 2, label: "Medium", color: "info" },
    { value: 1, label: "Low", color: "dark" },
  ];
  const statusList = [
    { value: 0, label: "New Task" },
    { value: 1, label: "Processing" },
    { value: 2, label: "Completed" },
    { value: 3, label: "In Review" },
    { value: 4, label: "Finished" },
  ];
  useEffect(async () => {
    await getallocatelistbyid();
    await gettasklist();
  }, []);

  const filterData = () => {
    let ntask = [];
    ntask =
      taskdata &&
      taskdata.filter((task) => {
        if (
          (task.t_priority == filtertask?.filterpriority ||
            filtertask?.filterpriority == 0) &&
          (task.t_title
            .toLowerCase()
            .includes(filtertask?.filtert.toLowerCase()) ||
            task.t_desc
              .toLowerCase()
              .includes(filtertask?.filtert.toLowerCase()) ||
            task.createdBy
              .toLowerCase()
              .includes(filtertask?.filtert.toLowerCase()) ||
            task.allocatedTo
              .toLowerCase()
              .includes(filtertask?.filtert.toLowerCase()))
        ) {
          return task;
        }
      });
    setnewTaskData(ntask);
  };

  useEffect(async () => {
    if (
      filtertask.filterpriority != undefined ||
      filtertask.filtert != undefined
    ) {
      await filterData();
    }
  }, [filtertask, taskdata]);

  const gettasklist = async () => {
    setTaskData(await fetchtasklistbyid(loginUser.u_id, loginUser.u_type));
  };

  const gettask = async (t_id) => {
    setformdata(await fetchtaskbytid(t_id));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: "10px",
    p: 4,
  };

  const taskStatusChange = async (cardId, targetLaneId, sourceLaneId) => {
    if (targetLaneId == sourceLaneId) {
    } else {
      let lfdata = new FormData();
      lfdata.append("t_id", cardId);
      lfdata.append("t_status", targetLaneId);
      const resp = await updatetaskstatus(lfdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        await gettasklist();
      }
    }
  };

  let sum = 0;

  let len = [[], [], [], [], []];

  newtaskData?.map((e, key) => {
    let temp = {
      id: e.t_id,
      taskTitle: `${e.t_title}`,
      createdDate: `${e.t_date}`,
      body: `${e.t_desc}`,
      taskCreatedPerson: `${e.createdBy}`,
      metadata: { id: e.t_id },
      person: `${e.allocatedTo}`,
      tags: {
        cardId: key,
        priority_Id: e.t_priority,
        taskPriority: e.t_priority,
        title:
          e.t_priority == 1
            ? "Low"
            : e.t_priority == 2
            ? "Medium"
            : e.t_priority == 3
            ? "High"
            : "Urgent",
      },
    };
    len[e.t_status].push(temp);
  });
  const taskList = {
    lanes: [
      {
        id: "0",
        title: "Open Tasks",
        className: "taskListColumn openTask",
        cards: len[0],
        label: `${len[0].length}`,
      },
      {
        id: "1",
        title: "Processing Task",
        className: "taskListColumn openTask",
        cards: len[1],
        label: `${len[1].length}`,
      },
      {
        id: "2",
        title: "Completed Task",
        className: "taskListColumn completeTask",
        label: `${len[2].length}`,
        cards: len[2],
      },
      {
        id: "3",
        title: "In Review Task",
        className: "taskListColumn inReviewTask",
        label: `${len[3].length}`,
        cards: len[3],
      },

      {
        id: "4",
        title: "Finished Task",
        className: "taskListColumn finishTask",
        label: `${len[4].length}`,
        cards: len[4],
      },
    ],
  };

  const getallocatelistbyid = async () => {
    if (loginUser.u_type == 2) {
      setallocatelist(
        await fetchallocatelistbyid(loginUser.p_user_id, loginUser.u_type)
      );
    } else {
      setallocatelist(
        await fetchallocatelistbyid(loginUser.u_id, loginUser.u_type)
      );
    }
  };

  const onChangeFunction = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };
  const handleClick = (id) => {
    setAnchorEl({ id: id });
  };

  const changepriority = async (t_id, t_priority) => {
    const resp = await updateTaskPrority(t_id, t_priority);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      await gettasklist();
      setAnchorEl({ id: 0 });
    }
  };
  const CustomCard = ({
    onClick,
    id,
    taskTitle,
    body,
    createdDate,
    tagStyle,
    taskCreatedPerson,
    tags,
    showDeleteButton,
    onDelete,
    person,
  }) => {
    return (
      <>
        <div className="taskCard">
          <header className="taskHeader">
            <div className="TaskTitle" onClick={onClick}>
              {taskTitle}
            </div>
            <a
              className=""
              style={{ position: "absolute", right: "0px", zIndex: "0" }}
              onClick={() => {
                setDvisible({ mval: true, id: id });
              }}
            >
              <Tooltip title="Delete" style={{ padding: 0, margin: 0 }}>
                <IconButton>
                  <DeleteForever
                    color="error"
                    style={{ height: "20px", width: "20px" }}
                  />
                </IconButton>
              </Tooltip>
            </a>
          </header>
          <div onClick={onClick}>
            <div className="taskBody">
              <p>{body}</p>
            </div>
            <div className="taskUserDetail">
              <div className="taskCreatedName">
                <b>Created By : {taskCreatedPerson}</b>
              </div>
              <div className="taskDate">
                <b>Date : {createdDate}</b>
              </div>
            </div>
          </div>
          <div className="taskFooter">
            <div>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>{person}</p>
            </div>
            <div style={{ position: "relative", width: "auto" }}>
              <a
                key={tags.title}
                {...tags}
                className="taskPriority"
                taskPriority={tags.taskPriority}
                onClick={() => handleClick(id)}
              >
                {tags.title}
              </a>
              <div
                className="priorityDiv"
                id={id}
                style={{
                  position: "absolute",
                  justifyContent: "flex-end",
                  bottom: "0",
                  display: "flex",
                  right: "0",
                }}
              >
                {anchorEl.id == id ? (
                  <ul
                    className="priorityList"
                    id={id}
                    style={{
                      display: "flex",
                      width: "115px",
                      fontSize: "13px",
                      flexDirection: "column",
                      background: "#fff",
                      boxShadow: "#7c7c7c 0px 0px 3px 0px",
                      borderRadius: "10px",
                    }}
                  >
                    {priorityList &&
                      priorityList.map((e) => {
                        if (e.value == tags.taskPriority) {
                          return (
                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderBottom: "1px solid #ebebeb",
                                }}
                                onClick={() => {
                                  setAnchorEl({ id: 0 });
                                }}
                              >
                                <Flag
                                  color={e.color}
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    marginRight: "5px",
                                  }}
                                />
                                {e.label}

                                <Check
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    margin: "auto",
                                    marginRight: "0",
                                  }}
                                />
                              </a>
                            </li>
                          );
                        } else {
                          return (
                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderBottom: "1px solid #ebebeb",
                                }}
                                onClick={() => {
                                  changepriority(id, e.value);
                                }}
                              >
                                <Flag
                                  color={e.color}
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    marginRight: "5px",
                                  }}
                                />
                                {e.label}
                              </a>
                            </li>
                          );
                        }
                      })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const submitformdata = async () => {
    let fdata = new FormData();
    fdata.append("u_allocated", formdata?.u_allocated);
    fdata.append("t_title", formdata?.t_title);
    fdata.append("t_status", formdata?.t_status);
    fdata.append("t_priority", formdata?.t_priority);
    fdata.append("t_desc", formdata?.t_desc);
    fdata.append("t_date", formdata?.t_date);
    fdata.append("file", formdata?.file);
    fdata.append("u_created_id", formdata?.u_created_id);
    fdata.append("u_type", formdata?.u_type);
    if (loginUser.u_id == formdata?.u_allocated && loginUser.u_type == 1) {
      fdata.append("p_u_id", loginUser?.u_id);
    } else if (loginUser.u_type == 2) {
      fdata.append("p_u_id", loginUser?.p_user_id);
    } else {
      fdata.append("p_u_id", loginUser?.u_id);
    }
    if (step == 1) {
      const resp = await insertTask(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setStep(0);
        setformdata({
          u_created_id: loginUser.u_id,
          u_type: loginUser.u_type,
        });
        gettasklist();
      }
    } else if (step == 2) {
      fdata.append("t_id", formdata?.t_id);
      const resp = await updatetaskdetails(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        setStep(0);
        setformdata({
          u_created_id: loginUser.u_id,
          u_type: loginUser.u_type,
        });
        gettasklist();
      }
    }
  };

  const cancelModal = () => {
    setDvisible({ mval: false, id: 0 });
  };

  const expo_columns = [
    { label: "No", key: "t_id" },
    { label: "Date", key: "t_date" },
    { label: "Title", key: "t_title" },
    { label: "Description", key: "t_desc" },
    { label: "Created By", key: "createdBy" },
    { label: "Allocated To", key: "allocatedTo" },
    { label: "Priority", key: "t_priority" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row) => row.t_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => row.t_date,
    },
    {
      name: "Title",
      selector: (row) => row.t_title,
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
    },
    {
      name: "Allocated To",
      selector: (row) => row.allocatedTo,
    },
    {
      name: "Priority",
      selector: (row) => (
        <>
          {priorityList &&
            priorityList.map((k) => {
              if (k.value == row.t_priority) {
                return k.label;
              }
            })}
        </>
      ),
      sortable: true,
    },
    {
      name: "Descripation",
      selector: (row) => row.t_desc,
    },
  ];

  const deletetask = async (t_id) => {
    const resp = await deleteTask(t_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      cancelModal();
      gettasklist();
    }
  };

  return (
    <>
      {/* Menu */}
      {/* Guard Modal */}
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletetask}
      />
      <Page title="Task | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Task
            </Typography>
          </Stack>
          {step == 1 || step == 2 ? (
            <>
              <Button
                onClick={() => {
                  setStep(0);
                  setformdata({
                    u_created_id: loginUser.u_id,
                    u_type: loginUser.u_type,
                  });
                }}
                style={{ marginBottom: "15px" }}
                variant="contained"
              >
                Back
              </Button>
              <Card
                style={{
                  padding: "25px",
                  paddingBottom: "25px",
                  marginBottom: "25px",
                }}
              >
                <Stack spacing={10} style={{ marginBottom: "15px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>
                          Allocate To
                        </label>
                        <Select
                          value={
                            allocatelist &&
                            allocatelist
                              .map((p) => {
                                if (p.u_id == formdata.u_allocated) {
                                  return { value: p.u_id, label: p.u_name };
                                }
                              })
                              .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="u_allocated"
                          onChange={(e) => {
                            setformdata({ ...formdata, u_allocated: e.value });
                          }}
                          options={
                            allocatelist != null &&
                            allocatelist.map((p) => {
                              return { value: p.u_id, label: p.u_name };
                            })
                          }
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>Priority</label>
                        <Select
                          value={
                            priorityList &&
                            priorityList
                              .map((p) => {
                                if (p.value == formdata.t_priority) {
                                  return { value: p.value, label: p.label };
                                }
                              })
                              .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="t_priority"
                          onChange={(e) => {
                            setformdata({ ...formdata, t_priority: e.value });
                          }}
                          options={priorityList}
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={10} style={{ marginBottom: "15px" }}>
                        <label style={{ marginBottom: "10px" }}>Status</label>
                        <Select
                          value={
                            statusList &&
                            statusList
                              .map((p) => {
                                if (p.value == formdata.t_status) {
                                  return { value: p.value, label: p.label };
                                }
                              })
                              .filter((n) => n)[0]
                          }
                          style={{ marginTop: "0px" }}
                          name="t_status"
                          onChange={(e) => {
                            setformdata({ ...formdata, t_status: e.value });
                          }}
                          options={statusList}
                        ></Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Title"
                          name="t_title"
                          value={formdata?.t_title}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3} style={{ marginBottom: "15px" }}>
                        <TextField
                          style={{ marginTop: "0px" }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          label="Descripation"
                          name="t_desc"
                          value={formdata?.t_desc}
                          onChange={onChangeFunction}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          style={{ marginTop: "15px" }}
                        >
                          <DatePicker
                            type="date"
                            name="t_date"
                            label="Date"
                            inputFormat="dd/MM/yyyy"
                            value={moment(formdata.t_date, "DD-MM-YYYY")}
                            onChange={(newDate) => {
                              setformdata({
                                ...formdata,
                                t_date: moment(newDate, "DD-MM-YYYY").format(
                                  "DD-MM-YYYY"
                                ),
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>

                <Button
                  style={{ marginTop: "15px" }}
                  variant="contained"
                  onClick={submitformdata}
                >
                  Save
                </Button>
              </Card>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setStep(1);
                  }}
                  style={{ marginBottom: "15px", background: "#1c4766" }}
                  variant="contained"
                >
                  Add Task
                </Button>
                <Button
                  onClick={() => {
                    step == 0 ? setStep(3) : setStep(0);
                  }}
                  style={{ marginBottom: "15px" }}
                  variant="contained"
                >
                  {step == 0 ? "Table View" : "Card View"}
                </Button>
              </div>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>
                    Select Priority
                  </label>
                  <Select
                    value={filter.defaulselect}
                    onChange={(e) => {
                      setFilter({ defaulselect: e, t_priority: e.value });
                      setfiltertask({
                        ...filtertask,
                        filterpriority: e.value,
                      });
                    }}
                    options={[
                      { value: 0, label: "All Priority" },
                      ...priorityList,
                    ]}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "5px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Search Task"
                    name="search"
                    value={filtertask.filtert}
                    onChange={(e) => {
                      setfiltertask({
                        ...filtertask,
                        filtert: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Grid>
              {step == 0 && (
                <Board
                  showDeleteButton
                  className="taskList"
                  data={taskList}
                  components={{ Card: CustomCard }}
                  onCardClick={async (cardId, metadata) => {
                    await gettask(cardId);
                    setStep(2);
                  }}
                  handleDragEnd={(
                    cardId,
                    sourceLaneId,
                    targetLaneId,
                    position,
                    cardDetails
                  ) => {
                    taskStatusChange(cardId, targetLaneId, sourceLaneId);
                  }}
                />
              )}

              {step == 3 && newtaskData && (
                <>
                  <Button className="btn btn-export">
                    <CSVLink
                      data={newtaskData}
                      headers={expo_columns}
                      filename={"TaskData.csv"}
                      className="btn btn-primary test"
                      target="_blank"
                    >
                      Export CSV
                    </CSVLink>
                  </Button>
                  <DataTable
                    title="Task List"
                    filter
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={newtaskData}
                  />
                </>
              )}
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
