import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Page from "../components/Page";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchchapterbyuser,
  fetchChaptList,
} from "src/utils/common";
import {
  GetMemberByChptId,
  UpdateBamount,
  deleteBusiness,
} from "src/api/commonapi";
import { EditSharp, DeleteSharp } from "@mui/icons-material";
import { async } from "q";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

const Business = () => {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });
  const [businessdata, setbusinessdata] = useState([]);
  const [chptdata, setchptdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [amount, setamount] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });
  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const getchptlist = async () => {
    // setchptdata(await fetchChaptList());
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };

  const getBusinnesAmount = async (amount, id) => {
    setstep(1);
    setamount({ b_amount: amount, b_id: id });
  };

  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };

  const getbusinesslist = async (ch_id, m_id) => {
    setbusinessdata(await fetchBusinessList(ch_id, m_id));
  };

  useEffect(async () => {
    getchptlist();
  }, []);

  const changeamount = async (e) => {
    e.preventDefault();
    const resp = await UpdateBamount(JSON.stringify(amount));
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      setamount({});
      setstep(0);
      getbusinesslist(filter.chpt_id, selectedmember.m_id);
    }
  };

  const deletebusiness = async (b_id) => {
    const resp = await deleteBusiness(b_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getbusinesslist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };

  const expo_columns = [
    { label: "No", key: "b_id" },
    { label: "Business Receiver", key: "b_by_name" },
    { label: "Business Giver", key: "b_to_name" },
    { label: "Date", key: "b_date" },
    { label: "Amount", key: "b_amount" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row) => row.b_id,
      sortable: true,
    },
    {
      name: "Business Receiver",
      selector: (row) => row.b_by_name,
      sortable: true,
    },
    {
      name: "Business Giver",
      selector: (row) => row.b_to_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.b_date,
      sortable: true,
    },
    {
      name: "Amount ",
      selector: (row) => row.b_amount,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,

      selector: (row) => (
        <>
          <>
            <Tooltip title="Edit Business">
              <IconButton
                onClick={() => {
                  getBusinnesAmount(row.b_amount, row.b_id);
                }}
              >
                <EditSharp color="warning" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Business">
              <IconButton
                onClick={() => {
                  setDvisible({ mval: true, id: row.b_id });
                }}
              >
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </>
        </>
      ),
    },
  ];
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletebusiness}
      />
      <Page title="Business | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Business
            </Typography>
          </Stack>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <ChapterSelectDropDown
                dropDownLabel={"Select Chapter"}
                selectedState={filter}
                chapterKey="chpt_id"
                handleChange={(e) => {
                  setFilter({ defaulselect: e, chpt_id: e.value });
                  getmemberbychp(e.value);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "10px" }}>Select Member</label>
              <Select
                value={selectedmember.defaulselect}
                onChange={(e) => {
                  setselectedmember({ defaulselect: e, m_id: e.value });
                  getbusinesslist(filter.chpt_id, e.value);
                }}
                options={
                  memberdata &&
                  memberdata.map((option) => {
                    return { value: option.m_id, label: option.m_name };
                  })
                }
              />
            </Stack>
          </Grid>
          {step == 1 && (
            <>
              <hr style={{ margin: "10px 0" }} />
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Amount</label>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Amount"
                    name="b_amount"
                    value={amount.b_amount}
                    onChange={(e) => {
                      setamount({ ...amount, b_amount: e.target.value });
                    }}
                  />
                </Stack>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0",
                }}
              >
                <Button variant="contained" onClick={changeamount}>
                  Change Busineess Amount
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setstep(0);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {businessdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={businessdata}
                    headers={expo_columns}
                    filename={"BusinessData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={businessdata}
                />
              </>
            )}
          </Card>
          {/* End Category Form */}
        </Container>
      </Page>
    </>
  );
};

export default Business;
