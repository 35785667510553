import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchchapterbyuser,
  fetchallcitylist,
  fetchchapterlistbycity,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { findTopper, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import Label from "src/components/Label";
import "../Assets/css/style.css";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function GeneralReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [attendanceData, setAttendanceData] = useState([]);
  const [topperData, setTopperData] = useState([]);
  const [chapoption, setchptoption] = useState([{ value: 0, label: "All" }]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);

  const [filtereddata, setFilteredData] = useState({
    startDate: null,
    endDate: null,
    no_of_top: 1,
    city_id:
      loginUser?.u_type == 1 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id != 1 &&
        loginUser?.management_p == 1)
        ? loginUser?.city_id
        : 0,
    chpt_id: 0,
    rType: 0,
    mActive: -1,
  });
  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const [cityList, setClityList] = useState([{ value: 0, label: "All" }]);

  useEffect(async () => {
    let res = await fetchallcitylist();
    let newCityList = [{ value: 0, label: "All" }];
    res?.map((res) => {
      newCityList.push({ value: res.city_id, label: res.city_name });
    });
    setClityList(newCityList);
  }, []);

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const reportType = [
    { value: 0, label: "All" },
    { value: 1, label: "V2V" },
    { value: 2, label: "Business Receiver" },
    { value: 3, label: "Business Giver" },
    { value: 4, label: "Reference Giver" },
    { value: 6, label: "Reference Receive" },
    { value: 5, label: "Visitor" },
    { value: 7, label: "Top Member Induction" },
  ];

  const memberActiveType = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "De-Active" },
  ];

  useEffect(async () => {
    getchptlist();
  }, []);

  const expo_columns = [
    { label: "Member Name", key: "m_name" },
    { label: "Topper For", key: "type" },
    { label: "Counter", key: "counter" },
  ];

  const searchReport = async () => {
    setTopperData([]);
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");
    start = start != "Invalid date" ? start : "";
    end = end != "Invalid date" ? end : "";
    let fndata = new FormData();
    fndata.append("startDate", start);
    fndata.append("endDate", end);
    fndata.append("no_of_top", filtereddata.no_of_top);
    fndata.append("city_id", filtereddata.city_id);
    fndata.append("chpt_id", filtereddata.chpt_id);
    fndata.append("filterType", filtereddata.rType);
    fndata.append("mActive", filtereddata.mActive);
    let resp = await findTopper(fndata);
    let topperList = [];
    let i = 1;
    if (resp?.status == 1) {
      // Get Object So access ti by using key
      if (resp?.data != null) {
        Object.keys(resp?.data).map((arr) => {
          if (arr != null) {
            resp?.data[arr].map((topper) => {
              if (topper != null) {
                // Get Array of Topper So Now Access Each Topper
                topper.map((tempdata) => {
                  topperList.push({
                    topper_id: i++,
                    counter: tempdata?.counter,
                    type: tempdata?.type,
                    m_name: tempdata?.member_name,
                  });
                });
              }
            });
          }
          // Get All Data base on select Type get inner Array
        });
      }

      setTopperData(topperList);
    } else {
      let notify = notification({
        type: resp?.status == 0 ? "error" : "success",
        message: resp?.message,
      });
      notify();
    }
  };

  const columns = [
    {
      name: "Member Name",
      selector: (row) => row.m_name,
      sortable: true,
    },
    {
      name: "Topper For",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Counter",
      selector: (row) => row.counter,
      sortable: true,
    },
  ];
  return (
    <>
      <Page title="LVB Topper Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Topper Report
            </Typography>
          </Stack>

          <Card
            style={{
              padding: "15px",
              marginBottom: "25px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={filtereddata?.startDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date"
                      value={filtereddata?.endDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={filtereddata?.no_of_top}
                    type="number"
                    label="Number Of Toppers (Ex-Top 10)"
                    name="no_of_top"
                    onChange={(e) => {
                      setFilteredData({
                        ...filtereddata,
                        no_of_top: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Select City</label>
                  <Select
                    menuPortalTarget={document.body}
                    isDisabled={
                      loginUser?.u_type == 1 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id != 1 &&
                        loginUser?.management_p == 1)
                        ? true
                        : false
                    }
                    value={
                      cityList &&
                      cityList.map((option) => {
                        if (option.value == filtereddata.city_id) {
                          return { value: option.value, label: option.label };
                        }
                      })
                    }
                    onChange={async (e) => {
                      setFilteredData({
                        ...filtereddata,
                        city_id: e.value,
                        chpt_id: 0,
                      });

                      let data = await fetchchapterlistbycity(e.value);
                      let newchptoption = [{ value: 0, label: "All" }];
                      data.map((e) => {
                        newchptoption.push({
                          value: e.chpt_id,
                          label: e.chpt_name,
                        });
                      });
                      setchptoption(newchptoption);
                    }}
                    options={cityList}
                  />
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Select Chapter</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={
                      filtereddata.chpt_id == 0
                        ? { value: 0, label: "All Chapter" }
                        : chptdata &&
                          chptdata
                            ?.map((c) => {
                              if (c.chpt_id == filtereddata.chpt_id) {
                                return {
                                  value: c.chpt_id,
                                  label: c.chpt_name,
                                };
                              }
                            })
                            .filter((n) => n)[0]
                    }
                    style={{ marginTop: "0px" }}
                    name="chpt_id"
                    onChange={(e) => {
                      setFilteredData({
                        ...filtereddata,
                        chpt_id: e.value,
                      });
                    }}
                    options={[
                      {
                        value: 0,
                        label: "All Chapter",
                      },
                      ...(chptdata &&
                        chptdata
                          ?.map((chpt) => {
                            if (filtereddata?.city_id != 0) {
                              if (chpt?.city_id == filtereddata?.city_id) {
                                return {
                                  value: chpt.chpt_id,
                                  label: chpt.chpt_name,
                                };
                              }
                            } else {
                              return {
                                value: chpt.chpt_id,
                                label: chpt.chpt_name,
                              };
                            }
                          })
                          .filter((f) => f != undefined)),
                    ]}
                  ></Select>
                </Stack>
              </Grid>

              <Grid item xs={2}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Select Type</label>
                  <Select
                    menuPortalTarget={document.body}
                    value={
                      reportType &&
                      reportType.map((option) => {
                        if (option.value == filtereddata.rType) {
                          return { value: option.value, label: option.label };
                        }
                      })
                    }
                    onChange={(e) => {
                      setFilteredData({ ...filtereddata, rType: e.value });
                    }}
                    options={
                      reportType &&
                      reportType.map((option) => {
                        return { value: option.value, label: option.label };
                      })
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={2}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>
                    Member Active Type
                  </label>
                  <Select
                    menuPortalTarget={document.body}
                    value={
                      memberActiveType &&
                      memberActiveType.map((option) => {
                        if (option.value == filtereddata.mActive) {
                          return { value: option.value, label: option.label };
                        }
                      })
                    }
                    onChange={(e) => {
                      setFilteredData({ ...filtereddata, mActive: e.value });
                    }}
                    options={
                      memberActiveType &&
                      memberActiveType.map((option) => {
                        return { value: option.value, label: option.label };
                      })
                    }
                  />
                </Stack>
              </Grid>

              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "12px",
                }}
              >
                <div>
                  <Button
                    className="mt-1"
                    variant="contained"
                    onClick={() => {
                      searchReport();
                    }}
                    // style={{ background: "#ff4842" }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      // setstartDate(null);
                      // setendDate(null);
                      setTopperData([]);
                      setFilteredData({
                        startDate: null,
                        endDate: null,
                        no_of_top: 1,

                        city_id:
                          loginUser?.u_type == 1 ||
                          (loginUser?.u_type == 2 &&
                            loginUser?.p_user_id != 1 &&
                            loginUser?.management_p == 1)
                            ? loginUser?.city_id
                            : 0,
                        chpt_id: 0,
                        rType: 0,
                        mActive: -1,
                      });
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {topperData && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={topperData}
                    headers={expo_columns}
                    filename={"Topper Data.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={topperData}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
