import { filter, get } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import {
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchV2vListByMember,
  fetchLvbCounterWeb,
  fetchallcitylist,
  strToAmountNumberConvert,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { getcitysearchcounter } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  Start,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { setDefaultOptions } from "date-fns";

export default function CityReport() {
  const [filterCounter, setFilterCounter] = useState([]);
  const [filtereddata, setFilteredData] = useState({
    startDate: null,
    endDate: null,
    filterType: 0,
    city_id: 0,
  });
  const [cityList, setClityList] = useState([]);

  useEffect(async () => {
    setClityList(await fetchallcitylist());
  }, []);
  const filterData = async () => {
    let start = moment(filtereddata.startDate).format("DD-MM-YYYY");
    let end = moment(filtereddata.endDate).format("DD-MM-YYYY");
    if (filtereddata.city_id == 0) {
      let notify = notification({
        type: "error",
        message: "Please Select  City",
      });
      notify();
    } else {
      start = start != "Invalid date" ? start : "";
      end = end != "Invalid date" ? end : "";
      let fndata = new FormData();
      fndata.append("startDate", start);
      fndata.append("endDate", end);
      fndata.append("city_id", filtereddata.city_id);
      fndata.append("filterType", filtereddata.filterType);
      let resp = await getcitysearchcounter(fndata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      notify();
      if (resp.status == 1) {
        let restest = [];
        resp.data.City_business_given_amt = strToAmountNumberConvert(
          resp.data.City_business_given_amt
        );
        resp.data.City_business_recive_amt = strToAmountNumberConvert(
          resp.data.City_business_recive_amt
        );
        restest.push(resp.data);
        setFilterCounter(restest);
      }
    }
  };
  const expo_columns = [
    { label: "City Total V2V", key: "City_v2v_count" },
    { label: "City Total Business Received", key: "City_business_recive_amt" },
    { label: "City Total Business Given", key: "City_business_given_amt" },
    {
      label: "City Total Reference Received",
      key: "City_reference_recive_count",
    },
    { label: "City Total Reference Given", key: "City_reference_given_count" },
    { label: "City Total Visitor", key: "City_visitor_count" },
  ];

  const columns = [
    {
      name: "City Total V2V",
      selector: (row, key) => row.City_v2v_count,
      sortable: true,
    },
    {
      name: "City Total Business Received",
      selector: (row) => row.City_business_recive_amt,
      sortable: true,
    },
    {
      name: "City Total Business Given",
      selector: (row) => row.City_business_given_amt,
      sortable: true,
    },
    {
      name: "City Total Reference Received",
      selector: (row) => row.City_reference_recive_count,
      sortable: true,
    },
    {
      name: "City Total Reference Given",
      selector: (row) => row.City_reference_given_count,
      sortable: true,
    },
    {
      name: "City Total Visitor",
      selector: (row) => row.City_visitor_count,
      sortable: true,
    },
  ];

  const getCityCounter = async (id) => {
    let test = [];
    let res = await fetchLvbCounterWeb(id);

    if (res != null) {
      res.City_business_given_amt = strToAmountNumberConvert(
        res?.City_business_given_amt
      );
      res.City_business_recive_amt = strToAmountNumberConvert(
        res?.City_business_recive_amt
      );
      test.push(res);
      await setFilterCounter(test);
    } else {
      setFilterCounter([]);
    }
  };
  return (
    <>
      <Page title="City Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              City Report
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Select City</label>
                  <Select
                    // value={filter.defaulselect}
                    onChange={(e) => {
                      setFilteredData({
                        ...filtereddata,
                        city_id: e.value,
                      });
                      getCityCounter(e.value);
                      // setSrdate({ s_date: null, e_date: null });
                    }}
                    options={
                      cityList &&
                      cityList?.map((e) => {
                        return { value: e.city_id, label: e.city_name };
                      })
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Card
            style={{
              padding: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <RadioGroup
                    style={{ marginTop: "0px" }}
                    row
                    defaultValue={0}
                    // value={getdata.e_type}
                    onChange={(e) => {
                      setFilteredData({
                        ...filtereddata,
                        filterType: e.target.value,
                      });
                    }}
                    // onChange={(e) => {
                    //   onChangeFunction(e);
                    // }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="e_type"
                  >
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio checked={filtereddata.filterType == 0 && true} />
                      }
                      label="Include Default Data"
                    />
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio checked={filtereddata.filterType == 1 && true} />
                      }
                      label="Don't Include Default Data"
                    />
                  </RadioGroup>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="startdate"
                      label="Start Date "
                      value={
                        filtereddata?.startDate != null
                          ? filtereddata?.startDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          startDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  spacing={3}
                  style={{ marginBottom: "15px", marginTop: "5px" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    style={{ marginTop: "15px" }}
                  >
                    <DatePicker
                      type="date"
                      name="enddate"
                      label="End Date "
                      value={
                        filtereddata?.endDate != null
                          ? filtereddata?.endDate
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newDate) => {
                        setFilteredData({
                          ...filtereddata,
                          endDate: newDate,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      filterData();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilteredData({
                        startDate: null,
                        endDate: null,
                        city_id: 0,
                        filterType: 0,
                      });
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {filterCounter && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={filterCounter}
                    headers={expo_columns}
                    filename={"CityData.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  title={
                    filterCounter?.[0]?.Type != undefined
                      ? filterCounter?.[0]?.Type + " City Record"
                      : ""
                  }
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={filterCounter}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
