import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchV2vList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchV2vListByMember,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import { deleteV2v, GetMemberByChptId } from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
  Filter,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { setDefaultOptions } from "date-fns";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import Label from "src/components/Label";

export default function V2vReport() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [v2vdata, setv2vdata] = useState([]);
  const [nv2vdata, setnv2vdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [nmemberdata, setnmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
    m_id: 0,
    startDate: "",
    endDate: "",
    selectType: 0,
    crossType: 0,
  });

  const [filtereddata, setFilteredData] = useState({
    startDate: "",
    endDate: "",
  });
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "V2V Receiver" },
    { value: 2, label: "V2V Giver" },
  ]);
  const [inOutChapterOptions, setInOutChapterOptions] = useState([
    { value: 0, label: "All" },
    { value: 1, label: "In-Chapter" },
    { value: 2, label: "Cross-Chapter" },
  ]);

  const filterData = () => {
    let nv2v = [];
    nv2v =
      v2vdata &&
      v2vdata.filter((v2v) => {
        if (
          (filter.selectType == 1 && v2v.v2v_to_id == filter.m_id) ||
          (filter.selectType == 2 && v2v.v2v_by_id == filter.m_id) ||
          filter.selectType == 0
        ) {
          if (filter.startDate != "" && filter.endDate != "") {
            if (
              moment(v2v.v2v_date, "DD-MM-YYYY").isBetween(
                filter.startDate,
                filter.endDate,
                "days",
                "[]"
              )
            ) {
              let res = handleCrossFilter(v2v);
              return res;
            }
          } else {
            let res = handleCrossFilter(v2v);
            return res;
          }
        }
      });

    setnv2vdata(nv2v);
  };
  const handleCrossFilter = (v2v) => {
    if (filter.crossType == 1 || filter.crossType == 2) {
      if (v2v.inOutChapter == filter.crossType) {
        return v2v;
      }
    } else {
      return v2v;
    }
  };

  useEffect(async () => {
    await filterData();
  }, [v2vdata, filter]);

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };

  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getv2vlist = async (ch_id, m_id) => {
    let res = await fetchV2vListByMember(ch_id, m_id);
    let newData = res.map((v2v) => {
      if (v2v.v2v_to_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(v2v.v2v_by_id)
          ? { ...v2v, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...v2v, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
        // return ({...v2v , crossData :''})
      } else if (v2v.v2v_by_id == m_id) {
        return memberdata
          .filter((member) => member.chpt_id == filter.chpt_id)
          .map((member) => member.m_id)
          .includes(v2v.v2v_to_id)
          ? { ...v2v, inOutChapter: 1, outChapterLabel: "In-Chapter" }
          : { ...v2v, inOutChapter: 2, outChapterLabel: "Cross-Chapter" };
      }
    });
    setv2vdata(newData);
  };
  const deletev2v = async (v_id) => {
    const resp = await deleteV2v(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getv2vlist(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "row_no" },
    { label: "V2V Giver", key: "v_by_name" },
    { label: "V2V Receiver", key: "v_to_name" },
    { label: "Date", key: "v2v_date" },
    { label: "Comment", key: "comment" },
    { label: "In/Out Chapter", key: "outChapterLabel" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row, key) => row.row_no,
      width: "100px",
      sortable: true,
    },
    {
      name: "V2V Giver",
      selector: (row) => row.v_by_name,
      sortable: true,
    },
    {
      name: "V2V Receiver",
      selector: (row) => row.v_to_name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.v2v_date,
      sortable: true,
    },
    {
      name: "Comment ",
      selector: (row) => row.comment,
      sortable: true,
    },
    {
      name: "In/Out Chapter",
      selector: (row) => (
        <div>
          <Label
            variant={"ghost"}
            color={row.inOutChapter == 1 ? "success" : "info"}
          >
            {row.outChapterLabel}
          </Label>
        </div>
      ),
    },
  ];

  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deletev2v}
      />
      <Page title="V2V Report | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              V2V Report
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <ChapterSelectDropDown
                  dropDownLabel={"Select Chapter"}
                  selectedState={filter}
                  chapterKey="chpt_id"
                  handleChange={(e) => {
                    setFilter({ ...filter, chpt_id: e.value });
                    getmemberbychp(e.value);
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Member</label>
                <Select
                  value={memberdata.map((member) => {
                    if (member.m_id == filter.m_id) {
                      return { value: member.m_id, label: member.m_name };
                    }
                  })}
                  onChange={(e) => {
                    setFilter({ ...filter, m_id: e.value });
                    getv2vlist(filter.chpt_id, e.value);
                  }}
                  options={
                    memberdata &&
                    memberdata.map((option) => {
                      return { value: option.m_id, label: option.m_name };
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Select Type</label>
                <Select
                  value={filterTypeOptions.map((c) => {
                    if (c.value == filter.selectType) {
                      return { value: c.value, label: c.label };
                    }
                  })}
                  onChange={async (e) => {
                    await setFilter({ ...filter, selectType: e.value });
                  }}
                  options={filterTypeOptions}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={3} style={{ marginBottom: "15px" }}>
                <label style={{ marginBottom: "10px" }}>
                  In / Cross Chapter
                </label>
                <Select
                  value={inOutChapterOptions.map((c) => {
                    if (c.value == filter.crossType) {
                      return { value: c.value, label: c.label };
                    }
                  })}
                  onChange={async (e) => {
                    setFilter({ ...filter, crossType: e.value });
                  }}
                  options={inOutChapterOptions}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="startdate"
                    label="Start Date "
                    value={filter?.startDate != "" ? filter?.startDate : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        startDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                spacing={3}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginTop: "15px" }}
                >
                  <DatePicker
                    type="date"
                    name="enddate"
                    label="End Date "
                    value={filter?.endDate != "" ? filter?.endDate : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newDate) => {
                      setFilter({
                        ...filter,
                        endDate: newDate,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    filterData();
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilter({
                      chpt_id: 0,
                      m_id: 0,
                      startDate: "",
                      endDate: "",
                      selectType: 0,
                      crossType: 0,
                    });
                    setv2vdata([]);
                    setnv2vdata([]);
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>

          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {nv2vdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={nv2vdata}
                    headers={expo_columns}
                    filename={"V2Vdata.csv"}
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={nv2vdata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
