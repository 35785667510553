import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Label from "../components/Label";
import Modal from "@mui/material/Modal";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Icon
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Page from "src/components/Page";
import Select from "react-select";
import {
  fetchallcityamabassador,
  fetchcityambassadorbyid,
  fetchCityList,
  fetchStateList,
  GetAllChapter
} from "src/utils/common";
import {
  changeCityAmbassador,
  deleteCityAmbassador,
  insertNewcityambassador,
  paymentWithdrawal,
  updateCityAmbassadorProfile,
  updateCityAmbssadorDetails
} from "src/api/commonapi";
import { notification } from "src/utils/messages";
import {
  AccountBalance,
  AccountBalanceWalletSharp,
  CurrencyRupee,
  DeleteSharp,
  EditSharp,
  FilterList,
  FilterListOffRounded,
  PersonAddAlt,
  PersonAddAlt1Sharp,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
export default function Bankdetail() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const [formdata, setformdata] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(async () => {
    setformdata(loginUser);
  }, []);

  const handleChange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (
      formdata?.b_account_h_name != undefined &&
      formdata?.b_account_h_name != "" &&
      formdata?.b_account_no != undefined &&
      formdata?.b_account_no != "" &&
      formdata?.b_branch_name != undefined &&
      formdata?.b_branch_name != "" &&
      formdata?.b_ifsc_code != undefined &&
      formdata?.b_ifsc_code != "" &&
      formdata?.b_name != undefined &&
      formdata?.b_name != "" &&
      formdata?.name != undefined &&
      formdata?.name != "" &&
      formdata?.mobile != undefined &&
      formdata?.mobile != ""
    ) {
      let resp = await updateCityAmbassadorProfile(JSON.stringify(formdata));
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message
      });
      notify();
      if (resp.status == 1) {
        localStorage.setItem("userData", JSON.stringify(formdata));
      }
    } else {
      let notify = notification({
        type: "error",
        message: "Enter All Details And Try Again"
      });
      notify();
    }
  };
  //   const handleShowPassword = () => {
  //     setShowPassword((show) => !show);
  //   };
  return (
    <Page title="Bank Details | Local Vocal Business Group">
      <Card style={{ paddingTop: "25px", paddingBottom: "25px", marginBottom: "25px" }}>
        <Container>
          <Stack spacing={10} style={{ marginBottom: "15px" }}>
            <Typography variant="h5" gutterBottom>
              Personal Detail
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Name"
                    name="name"
                    value={formdata?.name}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="email"
                    label="Email Id"
                    name="email"
                    value={formdata?.email}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="number"
                    label="Contact No"
                    name="mobile"
                    value={formdata?.mobile}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              {/* <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon>{showPassword ? <Visibility /> : <VisibilityOff />} </Icon>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    name="password"
                    value={formdata?.password}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid> */}
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    inputProps={{ readOnly: true, inputMode: "numeric", pattern: "[0-9]*" }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    label="Commission Percentage"
                    name="u_commission"
                    value={formdata?.u_commission}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
              Bank Detail
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    inputProps={{ inputMode: "text" }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Account Holder Name"
                    name="b_account_h_name"
                    value={formdata?.b_account_h_name}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="number"
                    label="Bank Account Number"
                    name="b_account_no"
                    value={formdata?.b_account_no}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Bank Name"
                    name="b_name"
                    value={formdata?.b_name}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="Branch Name"
                    name="b_branch_name"
                    value={formdata?.b_branch_name}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={3} style={{ marginBottom: "15px" }}>
                  <TextField
                    style={{ marginTop: "0px" }}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    label="IFSC Code"
                    name="b_ifsc_code"
                    value={formdata?.b_ifsc_code}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Button style={{ marginTop: "15px" }} onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </Container>
      </Card>{" "}
    </Page>
  );
}
