import { filter } from "lodash";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// material
import { Card, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Stack,
  Container,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import {
  fetchBusinessList,
  fetchVisitorList,
  fetchChaptList,
  fetchchapterbyuser,
  fetchTestimonialList,
} from "src/utils/common";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
// components
import Page from "../components/Page";
import {
  deleteTestimonialData,
  deleteVisitorData,
  GetMemberByChptId,
} from "src/api/commonapi";
import {
  DeleteForever,
  DeleteOutline,
  DeleteSharp,
  EditSharp,
} from "@mui/icons-material";
import { notification } from "src/utils/messages";
import Deletemodal from "./DeleteModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";

export default function Testimonial() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [dvisible, setDvisible] = useState({ mval: false, id: 0 });

  const [joindate, setjoinDate] = useState(null);
  const [chptdata, setchptdata] = useState([]);
  const [amount, setamount] = useState({});
  const [v2vdata, setv2vdata] = useState([]);
  const [chapoption, setchptoption] = useState([]);
  const [memberdata, setmemberdata] = useState([]);
  const [getdata, setgetdata] = useState({});
  const [step, setstep] = useState(0);
  const [filter, setFilter] = useState({
    chpt_id: 0,
  });

  const [selectedmember, setselectedmember] = useState({
    defaulselect: "",
    m_id: 0,
  });

  const getchptlist = async () => {
    setchptdata(await fetchchapterbyuser(loginUser.city_id, loginUser.u_type));
  };
  useEffect(
    (async) => {
      chptdata?.map((option) => {
        chapoption.push({ value: option.chpt_id, label: option.chpt_name });
      });
    },
    [chptdata]
  );

  const getmemberbychp = async (ch_id) => {
    setselectedmember({ defaulselect: "", m_id: 0 });
    setmemberdata(await GetMemberByChptId(ch_id));
  };
  const getTestimonialList = async (ch_id, m_id) => {
    setv2vdata(await fetchTestimonialList(m_id));
  };
  const deleteTestimonial = async (v_id) => {
    const resp = await deleteTestimonialData(v_id);
    let notify = notification({
      type: resp.status == 0 ? "error" : "success",
      message: resp.message,
    });
    notify();
    if (resp.status == 1) {
      getTestimonialList(filter.chpt_id, selectedmember.m_id);
      setDvisible({ mval: false, id: 0 });
    }
  };
  useEffect(async () => {
    getchptlist();
  }, []);
  const expo_columns = [
    { label: "No", key: "t_id" },
    { label: "Date", key: "date" },
    { label: "Testimonial Given", key: "t_given_by_member" },
    { label: "Testimonial Received", key: "t_given_to_member" },
    { label: "Comment", key: "comment" },
  ];

  const columns = [
    {
      name: "No",
      selector: (row) => row.t_id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Testimonial Given",
      selector: (row) => row.t_given_by_member,
      sortable: true,
    },
    {
      name: "Testimonial Received ",
      selector: (row) => row.t_given_to_member,
      sortable: true,
    },

    {
      name: "Comment",
      selector: (row) => row.comment,
      width: "500px",
    },

    {
      name: "Action",
      sortable: true,
      width: "100px",
      selector: (row) => (
        <>
          <>
            <Tooltip title="Delete Testimonial">
              <IconButton
                onClick={() => {
                  setDvisible({ mval: true, id: row.t_id });
                }}
              >
                <DeleteSharp color="error" />
              </IconButton>
            </Tooltip>
          </>
        </>
      ),
    },
  ];
  return (
    <>
      <Deletemodal
        visible={dvisible}
        setVisible={setDvisible}
        deletefunc={deleteTestimonial}
      />
      <Page title="Testimonial | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Testimonial List
            </Typography>
          </Stack>

          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <ChapterSelectDropDown
              dropDownLabel={"Select Chapter"}
              selectedState={filter}
              chapterKey="chpt_id"
              handleChange={(e) => {
                setFilter({ ...filter, chpt_id: e.value });
                getmemberbychp(e.value);
              }}
            />
          </Stack>
          <Stack spacing={3} style={{ marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }}>Select Member</label>
            <Select
              value={selectedmember.defaulselect}
              onChange={(e) => {
                setselectedmember({ defaulselect: e, m_id: e.value });
                getTestimonialList(filter.chpt_id, e.value);
              }}
              options={
                memberdata &&
                memberdata.map((option) => {
                  return { value: option.m_id, label: option.m_name };
                })
              }
            />
          </Stack>

          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            {v2vdata && (
              <>
                <Button className="btn btn-export">
                  <CSVLink
                    data={v2vdata}
                    headers={expo_columns}
                    filename={
                      "Testimonial Data" +
                      moment().format("DD-MM-YYYY") +
                      ".csv"
                    }
                    className="btn btn-primary test"
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <DataTable
                  style={{ overflow: "hidden" }}
                  filter
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={v2vdata}
                />
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
